import react from "react";
import "./App.css";
import { NewBgcForm } from "./Components/NewBgcForm";

function App() {


  return (
    <div className="App">
      <NewBgcForm />
    </div>
  );
}

export default App;
